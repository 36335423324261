// 绘画
const aippt = {
	pptTheme: {
		url: "/addons/chatgpt/ppt_new/get_pptTheme",
		method: "POST",
		desc: "获取ppt主题"
	},
	pptOutline: {
		url: "/addons/chatgpt/ppt_new/get_ppt_outline",
		method: "POST",
		desc: "获取ppt大纲"
	},
	pptMbList: {
		url: "/addons/chatgpt/ppt_new/get_template_list",
		method: "POST",
		desc: "获取ppt的模板"
	},
	generatePppt: {
		url: "/addons/chatgpt/ppt_new/generate_ppt",
		method: "POST",
		desc: "生成ppt"
	},
	getuserpptlist: {
		url: "/addons/chatgpt/ppt_new/get_user_ppt_list",
		method: "POST",
		desc: "获取ppt列表"
	},
	deluserppt: {
		url: "/addons/chatgpt/ppt_new/del_user_ppt",
		method: "POST",
		desc: "删除某个ppt"
	},
	get_one_template: {
		url: "/addons/chatgpt/ppt_new/get_one_template",
		method: "POST",
		desc: "new 获取模板"
	},
	complete_ppt: {
		url: "/addons/chatgpt/ppt_new/complete_ppt",
		method: "POST",
		desc: "ppt文件完成后 上传"
	},
	get_user_ppt: {
		url: "/addons/chatgpt/ppt_new/get_user_ppt",
		method: "POST",
		desc: "跳转 获取ppt详情"
	},
	set_ppt_id: {
		url: "/addons/chatgpt/ppt_new/get_user_outline",
		method: "POST",
		desc: "存储大纲内容 id"
	},
	get_ppt_id: {
		url: "/addons/chatgpt/ppt_new/get_user_outline",
		method: "GET",
		desc: "获取大纲内容 id"
	},
	
	
}

export default aippt
